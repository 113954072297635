<script>
import LiveSessionForm from "@/views/live-session/liveSessionForm";
import Component, { mixins } from "vue-class-component";
import ActionPanel from "@/components/panel/ActionPanel";
import TablePanel from "@/components/panel/TablePanel";
import ButtonBase from "@/components/dom/ButtonBase";
import SvgIcon from "@/components/dom/SvgIcon";
import Round from "@/components/dom/Round";

@Component({
  components: {
    ActionPanel,
    TablePanel,
    ButtonBase,
    SvgIcon,
    Round,
  },
  inject: {
    liveSessionService: "liveSessionService",
    fileUploadService: "fileUploadService",
    toastService: "toastService",
    visitorLevelService: "visitorLevelService",
    passCodeService: "passCodeService",
  },
})
export default class LiveSessionEdit extends mixins(LiveSessionForm) {
  formId = "edit-liveSession-form";
  speakers = [];

  get decoratedElements() {
    const childElements = this?.ui?.childElements ?? [];
    if (childElements.includes("visitorLevels")) {
      return this.elements;
    }

    return this.insertAfter(
      this.elements,
      this.passCodesFieldset(true),
      "invitation"
    );
  }

  get queryParameters() {
    return this.$route.params;
  }
  get fieldColumns() {
    return 2;
  }
  get actions() {
    return [
      {
        id: "save",
        type: "submit",
        text: this.translations.labels.button_save,
        leftIcon: "mdi-content-save-outline",
        disabled: () => {
          return this.fileUploadService.isUploading();
        },
        handle: this.save,
      },
    ];
  }

  async save(action, passesValidation) {
    this.errors = [];
    if (!passesValidation) {
      return;
    }

    try {
      this.model.duration = parseInt(this.model.duration);
      if (
        this.model?.invitation?.code &&
        this.model?.invitation?.enableInvitationLink
      ) {
        const invitationId = this.model?.invitation?.id;
        this.model.invitation = {
          code: this.model.invitation.code,
        };
        if (invitationId) {
          this.model.invitation.id = invitationId;
        }
      } else {
        delete this.model.invitation;
      }
      if(this.model.openingOption != "Popup") {
        this.model.localizations.forEach(loc => {
          loc.sidecardTitle = null;
          loc.sidecardLinkUri = null;
        });
      }
      await this.liveSessionService.update(this.model.id, this.model);
      this.toastService.success(this.translations.success.event_update);
      this.$router.push(
        `/live-session/list/${this.queryParameters.idParent}/${this.queryParameters.parentName}`
      );
    } catch (error) {
      this.errors = this.formatApiErrors(error);
    }
  }

  getFormTitle() {
    return this.translations.pageTitles.liveSessions_edit;
  }

  async afterLoad() {
    this.model = await this.liveSessionService.read(this.$route.params.id);
    if (!this.model.invitation) {
      this.model.invitation = {};
    }

    if (!this.model.visitorLevels) {
      this.model.visitorLevels = [];
    }

    this.model.speakers = this.model.users.map((u) => u.idUser);

    this.setVisitorLevels(
      await this.visitorLevelService.enabledLevels(this.model.id)
    );
    this.model.visitorLevels = this.currentVisitorLevelsValue();

    this.isReady = true;
  }
}
</script>